// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist. By throwing a
// 404 from the loader, we can force the error boundary to render which will
// ensure the user gets the right status code and we can display a nicer error
// message for them than the Remix and/or browser default.

import { Link, useLocation } from "react-router";

import { GeneralErrorBoundary } from "~/ui/error-boundary.tsx";

export const handle = {
  getSitemapEntries: () => null,
};

export async function loader() {
  throw new Response("Not found", { status: 404 });
}

export default function NotFound() {
  // due to the loader, this component will never be rendered, but we'll return
  // the error boundary just in case.
  return <ErrorBoundary />;
}

export function ErrorBoundary() {
  const location = useLocation();
  return (
    <GeneralErrorBoundary
      statusHandlers={{
        404: () => (
          <section>
            <div className="skew skew-top mr-for-radius">
              <svg
                className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
                viewBox="0 0 10 10"
                preserveAspectRatio="none"
              >
                <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
              </svg>
            </div>
            <div className="skew skew-top ml-for-radius">
              <svg
                className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
                viewBox="0 0 10 10"
                preserveAspectRatio="none"
              >
                <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
              </svg>
            </div>
            <div className="radius-for-skewed py-20">
              <div className="container mx-auto px-4">
                <img
                  className="mx-auto"
                  src="/assets/illustrations/pablo.png"
                  alt="Page non trouvée"
                />
                <div className="text-center">
                  <span className="mb-6 text-4xl font-bold text-green-800">
                    Page non trouvée
                  </span>
                  <h1 className="mb-2 text-4xl font-bold">
                    "{location.pathname}"
                  </h1>
                  <p className="mb-8 text-gray-600">
                    Nous sommes désolés, mais nous ne trouvons pas la page "
                    {location.pathname}" demandée
                  </p>
                  <div>
                    <Link
                      className="mb-2 inline-block w-full rounded-l-xl rounded-t-xl bg-green-800 px-6 py-2 font-bold leading-loose text-gray-50 hover:bg-green-700 lg:mb-0 lg:mr-4 lg:w-auto"
                      to="/"
                    >
                      Retour à la page d'accueil
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="skew skew-bottom mr-for-radius">
              <svg
                className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
                viewBox="0 0 10 10"
                preserveAspectRatio="none"
              >
                <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
              </svg>
            </div>
            <div className="skew skew-bottom ml-for-radius">
              <svg
                className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
                viewBox="0 0 10 10"
                preserveAspectRatio="none"
              >
                <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
              </svg>
            </div>
          </section>
        ),
      }}
    />
  );
}
